<script setup lang="ts">
import { watch } from 'vue'
import InlineSvg from 'vue-inline-svg'
import MainNavSettings from '@/layouts/components/MainNavSettings.vue'
import TrinityMainNav from '@/layouts/components/TrinityMainNav.vue'
import logoIcon from '@/assets/images/logo-icon.svg'
import logo from '@/assets/images/logo.svg'
import tithelyLogo from '@/assets/images/tithely-logo.svg'
import tithelyLogoFull from '@/assets/images/tithely-logo-full.svg'

import { useMe } from '@/queries/me'
import { useModalTriggers } from '@/composables/modals'
const { data: me, hasBreezeBundle } = useMe()
const { handleUrlModalTrigger } = useModalTriggers()

// Trigger modals if user is logged in
watch(
    () => me.value,
    newMe => {
        if (newMe) {
            handleUrlModalTrigger()
        }
    },
    { immediate: true }
)
</script>

<template>
    <header
        class="z-[1000] grid h-20 grid-cols-[minmax(0,1fr)_min-content_minmax(0,1fr)] items-center justify-between gap-6 !p-2 print:hidden md:!px-6">
        <div>
            <a href="/r/dashboard">
                <template v-if="hasBreezeBundle">
                    <InlineSvg
                        :src="tithelyLogo"
                        class="h-9 w-auto text-primary lg:hidden" />
                    <InlineSvg
                        :src="tithelyLogoFull"
                        class="hidden h-9 w-auto [--logo-leaf-color:rgb(var(--sp-color-primary))] [--logo-text-color:rgb(var(--sp-color-theme-foreground))] lg:visible lg:block" />
                </template>
                <template v-else>
                    <InlineSvg
                        :src="logoIcon"
                        class="h-9 w-auto text-primary lg:hidden" />
                    <InlineSvg
                        :src="logo"
                        class="hidden h-9 w-auto text-primary lg:visible lg:block" />
                </template>
            </a>
        </div>

        <TrinityMainNav />

        <div class="flex items-center justify-end">
            <MainNavSettings />
        </div>
    </header>
</template>
